import { Link } from "react-router-dom";
import { Content, FooterDiv, MobileContent, TardisLogoBg } from "./style";
import tardisLogo from "../../assets/images/tardis-logo.svg";
import tardisLogoWhite from "../../assets/images/tardis_logo_white.svg";

function Footer() {
  const COMPANY_NAME = "타디스테크놀로지";
  const COMPANY_CEO = "이용권";
  const COMPANY_JEJU_ADDR = "제주특별자치도 제주시 신대로 145, 2층 2-30호 (연동, 맨써빌딩)";
  const COMPANY_EMAIL = "evplug@tardistechnology.com";
  const COMPANY_ID = "741-86-00430";
  const COPYRIGHT = "Copyright ⓒTardis Technology, Inc All tights reserved.";

  return (
    <FooterDiv>
      <Content>
        {COMPANY_NAME} | 대표 {COMPANY_CEO} | {COMPANY_JEJU_ADDR} <br />
        이메일 : {COMPANY_EMAIL} | 사업자 : {COMPANY_ID} | <Link to="/policy">이용약관</Link> | <Link to="/privacy">개인정보처리방침</Link> |{" "}
        {COPYRIGHT}
      </Content>
      <MobileContent>
        {COMPANY_NAME} | 대표 {COMPANY_CEO} <br />
        {COMPANY_JEJU_ADDR} <br />
        이메일 : {COMPANY_EMAIL} | 사업자 : {COMPANY_ID} | <Link to="/policy">이용약관</Link> | <Link to="/privacy">개인정보처리방침</Link> |{" "}
        {COPYRIGHT}
      </MobileContent>
      <a href="https://www.tardistechnology.com" target="_blank">
        <TardisLogoBg src={tardisLogoWhite}></TardisLogoBg>
      </a>
    </FooterDiv>
  );
}
export default Footer;
