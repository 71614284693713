/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { Blank, Div, EvButton, Font, Img } from "../../styles/commonStyle";
import { disabledFontColor, greyColor, greyColor2, primaryColor, redColor } from "../../styles/theme";
import { ChargingListContent, ChargingListHead, NotifiContainer, NotifiContent, NotifiInfo, ScrollDiv, StyledTotal, TextRedStrong, TextStrong } from "./style";
import { showUserSelector, userChargingSelector, userPaidFailSelector, userRepaySelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import { ResponseList } from "../../model/responseList";
import { useEffect, useLayoutEffect, useState } from "react";
import EvModal from "../../components/modal";
import { TodayFormatYYYYMMDDHHmmssSSS, UtctoLocaleDateTimeFormmatter, addCommas, ediDateNoFormat, formatDate, getUserIdFromToken, makeEncryptData, makeMoid, todayNoFoarmat } from "../../util";
import Pagination from "../../components/pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchList from "../../hooks/useFetchList";
import { Form } from "../../styles/commonStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { Charging } from "../../model/charging";
import Loading from "../../components/loading/loading";
import { isRepayPopupOpenState, modalState, pageState, userInfoState, userRepayState } from "../../recoil/atoms";
import { User } from "../../model/user";
import useFetch from "../../hooks/useFetch";
import { RoundButton } from "../../components/modal/style";
import caution from "../../assets/images/icon-caution.png";
import RepayPopup from "../../components/modal/repayPopup";
import { PaidFailModel } from "../../model/paidFailModel";
import { isMobile } from "react-device-detect";

function ChargingHistoryPage() {
  const [currentPage, setPage] = useRecoilState(pageState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [isLoading, setLoading] = useState(true);
  const [isRepayLoading, setRepayLoading] = useState(false);
  const [chargingList, setChargingList] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [endDate, setEndDate] = useState<Date | null | undefined>();
  const [startDateString, setStartDateString] = useState<string | null | undefined>();
  const [endDateString, setEndDateString] = useState<string | null | undefined>();
  const [getSearch, setSearch] = useState<any>();
  const [isExec, setExec] = useState(false);
  const [totalStartDate, setTotalStartDate] = useState<any>();
  const [totalEndDate, setTotalEndDate] = useState<any>();
  const [Moid, setMoid] = useState("");
  const [EdiDate, setEdiDate] = useState("");
  const [EncryptData, setEncryptData] = useState("");
  const [isOpen, setIsOpen] = useRecoilState(isRepayPopupOpenState);
  const [onPopup, setOnPopup] = useState(0);
  const [isRePaySuccess, setIsRePaySuccess] = useState(false);
  const location = useLocation();
  const naviagate = useNavigate();
  const params = new URLSearchParams(location.search);
  const cPage = params.get("currentPage") ?? "1";
  const [isRepay, setIsRepay] = useState(false);
  const [failList, setFailList] = useState<PaidFailModel[]>([]);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  let userId = getUserIdFromToken();

  let accessToken = window.sessionStorage.getItem("accessToken");
  const response: ResponseModel<User> = useFetch({
    recoilSelector: showUserSelector({ token: accessToken! }),
    query: userId,
  });

  useEffect(() => {
    if (response?.result === RESULT.OK) {
      setUserInfo(response.data!);
    }
  }, [response]);

  const paidFailRes: ResponseModel<PaidFailModel[]> = useFetchList({
    recoilState: userPaidFailSelector,
  });

  useEffect(() => {
    if (paidFailRes?.result === RESULT.OK) {
      setFailList(paidFailRes.data!);
    }
  }, [paidFailRes]);

  useEffect(() => {
    if (failList && failList.length > 0) {
      // failList 내의 항목 중 paidFailedPrice가 0이 아닌 것이 있는지 확인
      const hasNonZeroFailedPrice = failList.some(item => item.paidFailedPrice !== 0);

      if (hasNonZeroFailedPrice) {
        setIsRepay(true);
      } else {
        setIsRepay(false);
      }
    } else {
      setIsRepay(false);
    }
  }, [failList]);

  useEffect(() => {
    if (onPopup === 1) {
      setIsOpen(true);
    }
  }, [onPopup, setIsOpen]);


  const query2: any = {
    currentPage: currentPage,
    perPage: 10,
  };
  if (startDateString) {
    if (startDate) {
      query2.beginDts = startDate?.toISOString()
    }
  }
  if (endDateString) {
    if (endDate) {
      query2.beginDte = endDate?.toISOString()
    }
  }

  const userChargingResponse: any = useFetchList({
    recoilState: userChargingSelector,
    query: query2,
    currentPage: currentPage,
    search: getSearch
  })

  const execSearch = () => {
    setPage(1);
    setExec(true);
    setTotalStartDate(startDate);
    setTotalEndDate(endDate);
  };
  const execEnterSearch = (e: any) => {
    if (e.key === "Enter") {
      execSearch();
    }
  };
  useEffect(() => {
    if (userChargingResponse?.result === RESULT.FAIL) {
      setLoading(false);
      setModalOpen(true);
    }
    if (userChargingResponse?.result === RESULT.OK) {
      setLoading(false);
      setChargingList(userChargingResponse);
    }
  }, [userChargingResponse]);

  useEffect(() => {
    setSearch(startDateString + "-" + endDateString);
    if (startDateString && endDateString) {
      naviagate(`/charging?currentPage=${currentPage}&startDts=${startDateString}&startDte=${endDateString}`);
    } else if (startDateString && !endDateString) {
      naviagate(`/charging?currentPage=${currentPage}&startDts=${startDateString}`);
    } else if (!startDateString && endDateString) {
      naviagate(`/charging?currentPage=${currentPage}&startDte=${endDateString}`);
    }
    else {
      naviagate(`/charging?currentPage=${currentPage}`);
    }
    setExec(false);
  }, [isExec]);

  useLayoutEffect(() => {
    setPage(Number(cPage));
    if (params.get("startDts") && params.get("startDte")) {
      setStartDateString(params.get("startDts")!);
      setEndDateString(params.get("startDte")!);
      setSearch(startDateString + "-" + endDateString);
    }
  }, []);

  const startDateHandler = (date: Date | null) => {
    if (endDate! < date!) {
      return;
    }
    setStartDate(date);
    setStartDateString(UtctoLocaleDateTimeFormmatter(date?.toDateString()!));
  };

  const endDateHandler = (date: Date | null) => {
    setEndDate(date);
    setEndDateString(UtctoLocaleDateTimeFormmatter(date?.toDateString()!));
  };

  useEffect(() => {
    const moid = makeMoid();
    const ediDate = ediDateNoFormat();

    setMoid(moid);
    setEdiDate(ediDate);
    setEncryptData(makeEncryptData(ediDate, moid));

  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = isMobile
      ? `${process.env.REACT_APP_SMARTRO_MOBILE}${todayNoFoarmat()}`
      : `${process.env.REACT_APP_SMARTRO_WEB}${todayNoFoarmat()}`;
    script.async = false;
    script.addEventListener('load', () => setLoading(true));
    document.body.appendChild(script);
  }, []);

  const requstPay = () => {
    // @ts-ignore
    window.smartropay.init({
      // mode: "STG",
      mode: "REAL",
      actionUri: '/ssb/interface.do'
    });

    // @ts-ignore
    window.smartropay.payment({
      FormId: 'paycardForm'
    });
  }

  const onClickChangePaycardHandler = () => {
    // 결제카드 변경
    requstPay();
  }

  const onClickRePayHandler = () => {
    setOnPopup(1);
  }

  // 로딩 추가
  const repayOnClick = useRecoilCallback(({ snapshot }) => async () => {
    setRepayLoading(true);
    try {
      const repayResult = await snapshot.getPromise(userRepaySelector(Date.now()));

      if (repayResult && repayResult.result === "OK") {
        if (repayResult.data) {
          setRepayLoading(false);
          setIsRePaySuccess(true);
          setIsRepay(false);
        } else {
          setRepayLoading(false);
          setIsRePaySuccess(false);
        }
      } else {
        setIsRePaySuccess(false);
      }
    } catch (error) {
      console.error("Repay 요청 중 오류 발생:", error);
      setRepayLoading(false);
      setIsRePaySuccess(false);
    }
    setOnPopup(2);
  });

  return (
    <>
      <Div className="w-full flex justify-end items-center">
        <DatePicker
          selected={startDate}
          onChange={(date) => startDateHandler(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="DatePicker"
          placeholderText="검색 시작일"
        />
        &nbsp;&nbsp;~&nbsp;&nbsp;
        <DatePicker
          selected={endDate}
          onChange={(date) => endDateHandler(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="DatePicker"
          placeholderText="검색 종료일"
          onKeyDown={(e) => execEnterSearch(e)}
        />
        <EvButton color="#fff" bgColor={primaryColor} className="ml-[10px]" padding="0.5rem 1.5rem" onClick={(e) => execSearch()}>
          조회
        </EvButton>
      </Div>

      {chargingList !== undefined && chargingList.data.chrgrElctcList.length > 0 && (totalStartDate || totalEndDate) ? (<GetTotalPoint totalStartDate={totalStartDate} totalEndDate={totalEndDate} chargingList={chargingList} />) : undefined}
      {isRepay && <NotifiContainer>
        <NotifiContent>
          <div>
            <div style={{ 'display': 'flex', 'alignItems': 'center', 'gap': '4px' }}>
              <Img src={caution} width="18px" height="15px" />
              <div>현재 아래의 <TextRedStrong>미결제 내역</TextRedStrong>이 있어 멤버십카드 이용이 <TextRedStrong>정지</TextRedStrong>되었습니다. 이용 정지를 해제하시려면 결제를 완료해주세요.</div>
            </div>
            {
              chargingList != undefined && chargingList.data.chrgrElctcList.length > 0 && failList.length > 0 &&
              <NotifiInfo>
                {chargingList.data.chrgrElctcList[0].stationNm} {UtctoLocaleDateTimeFormmatter(failList[0].paidDt)} <TextStrong>{addCommas(failList[0].paidFailedPrice)}원</TextStrong> <TextRedStrong>- {failList[0].failedReason ?? ''}</TextRedStrong>
              </NotifiInfo>
            }
          </div>
          <div style={{ 'display': 'flex', 'gap': '10px', 'justifyContent': 'end' }}>
            <RoundButton fill={false} width="130px" onClick={() => onClickChangePaycardHandler()}>결제카드 변경</RoundButton>
            <RoundButton fill={true} width="130px" onClick={() => onClickRePayHandler()}>결제 재시도</RoundButton>
          </div>
        </NotifiContent>
        <Form id="paycardForm" name="paycardForm" method="post">
          <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
          <input type="hidden" name="Mid" maxLength={10} value={process.env.REACT_APP_SMARTRO_MID} placeholder="" />
          <input type="hidden" name="Moid" maxLength={40} value={Moid} placeholder="특수문자 포함 불가" />
          <input type="hidden" name="MallUserId" maxLength={20} value={TodayFormatYYYYMMDDHHmmssSSS()} placeholder="" />
          <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />
          <input type="hidden" name="UserIp" maxLength={20} value="10.0.0.1" placeholder="" />
          <input type="hidden" name="ReturnUrl" size={100} className="input" value={process.env.REACT_APP_API_URL + `/api/v1/users/changePaycard/failedRepay/result?id=${response?.data?.id}`} />
          <input type="hidden" name="VerifyValue" maxLength={100} className="input" value={EncryptData} placeholder="위/변조방지 HASH 데이터" />
          <input type="hidden" name="EncodingType" maxLength={14} value="utf-8" placeholder="utf-8 or euc-kr" />
          <input type="hidden" name="SspMallId" maxLength={20} value={process.env.REACT_APP_SMARTRO_SSPMALLID} placeholder="테스트용 SspMallId" />
          <input type="hidden" name="EdiDate" maxLength={14} value={EdiDate} placeholder="" />
          <input type="hidden" name="IsPwdPass" maxLength={1} value="Y" placeholder="" />
        </Form>
      </NotifiContainer>
      }
      <Blank height="12px" />
      <ScrollDiv className="overflow-x-scroll w-full">
        <ChargingListHead>
          <Font className="w-[13%] text-center flex justify-center" fontSize="0.9rem">
            충전소명
          </Font>
          <Font className="w-[10%] text-center flex justify-center" fontSize="0.9rem">
            충전기번호
          </Font>
          <Font className="w-[30%] text-center flex justify-center" fontSize="0.9rem">
            충전시간 (시작시간 ~ 종료시간)
          </Font>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            충전량(kWh)
          </Font>
          <div className="w-[24%] flex flex-col">
            <div className="w-[100%] flex justify-center border-b border-slate-300 pb-2.5">
              <Font className="w-[100%] text-center flex justify-center" fontSize="0.9rem">
                결제금액
              </Font>
            </div>
            <div className="w-[100%] flex flex-row pt-2.5">
              <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                신용카드(원)
              </Font>
              <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                포인트(P)
              </Font>
              <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                합계
              </Font>
            </div>
          </div>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            결제상태
          </Font>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            포인트(P)
          </Font>
        </ChargingListHead>

        {!(chargingList?.data.chrgrElctcList.length > 0) && userChargingResponse?.result === RESULT.OK ? (
          <Font className="mt-5 flex justify-center">충전 이력이 존재하지 않습니다.</Font>
        ) : (

          <>
            {chargingList && chargingList.data && chargingList.data.chrgrElctcList && chargingList.data.chrgrElctcList?.map((value: any, idx: number) => <GetListLine value={value} key={"chargingList" + idx + 1} />)}
          </>
        )}
      </ScrollDiv>

      {isLoading ? (
        <Loading isLoading={true} />
      ) : (
        <Div className="flex mt-5 mb-5">
          <Pagination totalPage={chargingList?.data.totPage!} currentPage={"1"} type="charging" />
        </Div>
      )}
      <EvModal msg={userChargingResponse?.msg} />
      {
        (onPopup == 1) ?
          <>
            {isRepayLoading ? <Loading isLoading={true} /> :
              <RepayPopup
                onClick={repayOnClick}  // 여기를 수정했습니다
                onCloseClick={() => setOnPopup(0)}
                closeBtnText="취소"
                onClickBtnText="결제 재시도"
                content={
                  <>
                    <div style={{ 'fontSize': '18px' }}>결제 실패 요금 <span style={{ 'fontWeight': '700' }}>{addCommas(failList[0].paidFailedPrice) ?? 0}원</span>에 대한 결제를 재시도하시겠습니까?</div>
                    <div style={{ 'fontSize': '18px' }}>[결제카드 정보 : {response?.data?.paycardData?.paycardCo} {response?.data?.paycardData?.paycardNo}]</div>
                    <div style={{ 'marginTop': '14px', 'color': '#878787', fontSize: '16px' }}>*재결제 후 타사 로밍 충전기 정상 이용까지 최대 24시간 소요될 수 있습니다.</div>
                  </>
                }
              />}
          </> :
          onPopup == 2 && isRePaySuccess ? <RepayPopup
            onCloseClick={() => window.location.reload()}
            content='결제가 완료되었습니다.'
          /> :
            onPopup == 2 && !isRePaySuccess ? <RepayPopup
              onCloseClick={() => setOnPopup(0)}
              content={
                <>
                  결제에 실패하였습니다.<br />
                  카드 관련 실패의 경우 결제카드를 변경해주시고,<br />
                  지속적으로 결제에 실패하는 경우, 고객센터(02-6956-3055)<br />
                  로 문의 주시기 바랍니다.
                </>
              }
            /> :
              null

      }
    </>
  );
}

function GetTotalPoint({ totalStartDate, totalEndDate, chargingList }: { totalStartDate: any, totalEndDate: any, chargingList: any }) {
  return (
    <>
      <Blank height="12px" />
      <StyledTotal>
        <Font className="w-[10%] text-center flex justify-center font-medium bg-gray-112" fontSize="0.9rem">
          기간별 합계
        </Font>
        <Font className="w-[5%] text-center flex justify-center font-medium" fontSize="0.9rem">
          기간
        </Font>
        <Font className="w-[20%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {formatDate(totalStartDate)} ~ {formatDate(totalEndDate)}
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          충전량
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {chargingList?.data.totalElctcQy ? chargingList?.data.totalElctcQy : "0"}
        </Font>
        <Font className="w-[15%] text-center flex justify-center font-medium" fontSize="0.9rem">
          충전금액(원)
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {chargingList?.data.totalElctcPc ? chargingList?.data.totalElctcPc : "0"}
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          포인트(P)
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {chargingList?.data.totalMileage ? chargingList?.data.totalMileage : "0"}
        </Font>
      </StyledTotal>
    </>
  )
}

function GetListLine({ value }: { value: any }) {
  let paySttus: string = value.paySttus;
  switch (paySttus) {
    case "READY":
      paySttus = "결제대기";
      break;
    case "SUCCESS":
      paySttus = "결제성공";
      break;
    case "EXCEPT":
      paySttus = "결제예외";
      break;
    case "FAIL":
      paySttus = "결제실패";
      break;
    case "ALL_CANCEL":
      paySttus = "전체취소";
      break;
    case "ACCOUNT":
      paySttus = "계좌입금";
      break;
    case "ADMIN_CANCEL":
      paySttus = "관리자취소";
      break;
    case "TAX":
      paySttus = "세금계산서";
      break;
    case "CANCEL_FAIL":
      paySttus = "취소실패";
      break;
    case "CANCEL_READY":
      paySttus = "취소대기";
      break;
    default:
      paySttus = "알수없음";
      break;
  }
  // 마일리지 적립 실패 케이스 : FAIL, SEND_FAIL, NOT_PAID, SUCCESS
  return (
    <ChargingListContent style={{ 'backgroundColor': paySttus === "결제실패" ? 'rgba(255,164,164,0.12)' : 'transparent' }}>
      <Font className="w-[13%] text-center flex justify-center" fontSize="0.9rem">
        {value.stationNm}
      </Font>
      <Font className="w-[10%] text-center flex justify-center" fontSize="0.9rem">
        {value.chrgrIdntfr}
      </Font>
      <Font className="w-[30%] text-center flex justify-center" fontSize="0.9rem">
        {value.beginDt && value.endDt
          ? `${UtctoLocaleDateTimeFormmatter(value.beginDt.toString())} ~ ${UtctoLocaleDateTimeFormmatter(value.endDt.toString())}`
          : "-"}
      </Font>
      {
        paySttus === "결제대기" || paySttus === "결제성공" || paySttus === "결제예외" || paySttus === "알수없음" ? <>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            {value.elctcQy ?? "-"}
          </Font>
          <div className="w-[24%] flex">
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidCardPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidPointPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {addCommas(value.paidPrice) ?? "-"}
            </Font>
          </div>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            {paySttus}
          </Font>
          {
            value.mileage < 0 ? <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              -{value.mileage}
            </Font> : value.mileage == 0 ? <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              0
            </Font> : <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              +{value.mileage}
            </Font>
          }
        </> : paySttus === "취소대기" ? <>
          <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
            {value.elctcQy ?? "-"}
          </Font>
          <div className="w-[24%] flex">
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidCardPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidPointPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {addCommas(value.paidPrice) ?? "-"}
            </Font>
          </div>
          <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
            {paySttus}
          </Font>
          {
            value.mileageSttus === "SUCCESS" ? <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
              +{value.mileage}
            </Font> : value.mileage < 0 ? <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
              -{value.mileage}
            </Font> : <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
              {value.mileage}
            </Font>
          }
        </> : <>
          {
            value.elctcQy == 0 ? <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              {`${value.elctcQy}`}
            </Font> : <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              {`-${value.elctcQy}`}
            </Font>
          }
          <div className="w-[24%] flex">
            {
              value.paidCardPrice == 0 ? <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                {`${addCommas(value.paidCardPrice)}`}
              </Font> : <Font className="w-[33%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
                {`-${addCommas(value.paidCardPrice)}`}
              </Font>
            }
            {
              (value.paidPointPrice == 0 || value.paidPointPrice == null) ? <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                0
              </Font> : <Font className="w-[33%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
                -{value.paidPointPrice}
              </Font>
            }
            {
              value.paidPrice == 0 ? <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                {`${addCommas(value.paidPrice)}`}
              </Font> : <Font className="w-[33%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
                {`-${addCommas(value.paidPrice)}`}
              </Font>
            }
          </div>
          <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
            {paySttus}
          </Font>
          {
            value.mileageSttus === "SUCCESS" ? <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              +{value.mileage}
            </Font> : value.mileage < 0 ? <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              -{value.mileage}
            </Font> : <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              {0}
            </Font>
          }

        </>
      }

    </ChargingListContent>
  );
}

export default ChargingHistoryPage
